import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row, Upload, DatePicker } from "antd";
import { useParams } from "react-router";
import moment from "moment";

import CropEventImageModalBody from "./CropEventImageModalBody";
import InputWithLabel from "../../shared/InputWithLabel";
import BackLink from "../../shared/BackLink";
import TextAreaWithLabel from "../../shared/TextAreaWithLabel";
import { getPlacesRequest } from "../../../redux/places/placesActions";
import {
  clearEventAction,
  clearEventErrorAction,
  createEventRequest,
  getEventRequest,
  updateEventRequest
} from "../../../redux/admin/events/eventsActions";

const EventVerificationSchema = yup.object().shape({
  name: yup.string().trim().max(25).required("Name can not be empty"),
  dates: yup.array(yup.date()).required(),
  link: yup.string().trim().required("Link can not be empty"),
  description: yup.string().trim().max(200).required("Description can not be empty").nullable()
});

const Event = ({
  event,
  error,
  isProcessing,
  clearEvent,
  clearError,
  createEvent,
  updateEvent,
  getEvent
}) => {
  const { id } = useParams();

  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [noImageError, setNoImageError] = useState(false);
  const [cropImageUrl, setCropImageUrl] = useState();
  const [isCropperOpen, setIsCropperOpen] = useState(false);

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: EventVerificationSchema
  });

  useEffect(() => {
    clearEvent();
    if (id !== "new") {
      getEvent({ id });
    }

    return () => clearEvent();
  }, [id]);

  useEffect(() => {
    reset({
      name: event.name,
      description: event.description,
      link: event.link,
      dates: [
        event.fromDate ? moment(event.fromDate) : null,
        event.toDate ? moment(event.toDate) : null
      ]
    });
    setImageUrl(event.imageUrl);
  }, [event]);

  const clearErrorHandler = () => {
    if (error) {
      clearError();
    }
  };

  const submitHandler = (data) => {
    if (!imageUrl) {
      return setNoImageError(true);
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("link", data.link);
    formData.append("fromDate", moment(data.dates[0]).format("YYYY-MM-DD"));
    formData.append("toDate", moment(data.dates[1]).format("YYYY-MM-DD"));
    imageUrl !== event.imageUrl && formData.append("image", image);

    if (id === "new") {
      createEvent({ body: formData });
    } else {
      updateEvent({ id: id, body: formData });
    }
  };

  return (
    <div className="admin-event">
      <Row>
        <Col span={24}>
          <BackLink label="Back to events" url="/admin/events"/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Row>
              <Col span={24} className="form-section">
                <Row>
                  <div className="form-section__title">{id === "new" ? "New event" : "Edit event"}</div>
                </Row>
                <Row>
                  <Col span={16} style={{ paddingRight: "19px" }}>
                    <Row>
                      <InputWithLabel
                        name="name"
                        className="form-section__field"
                        control={control}
                        id="name"
                        onChange={([e]) => {
                          clearErrorHandler();

                          return e.target.value;
                        }}
                        label="Name"
                        defaultValue={event.name}
                        error={errors.name}
                        placeholder="Enter Name"
                        maxLength={25}
                      />
                    </Row>
                    <Row>
                      <Col span={24} className="form-section__field">
                        <label htmlFor="dates" className="form-label">Period</label>
                        <Controller
                          name="dates"
                          as={<DatePicker.RangePicker
                            showArrow={true}
                            className={`form-section__input${errors.dates ? " form-section__field--error" : ""}`}
                            format="DD-MM-YYYY"
                            value={[
                              event.fromDate ? moment(event.fromDate) : null,
                              event.toDate ? moment(event.toDate) : null
                            ]}
                          />}
                          disabledDate={current => current && current < moment().startOf("day")}
                          placeholder={["From date", "To date"]}
                          control={control}
                          onChange={([e]) => e}
                          showToday={false}
                        />
                        {(errors.dates &&
                          <span className="profile__error">Please check the dates</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "19px" }}>
                    <div className={`file-upload__container${noImageError ? " file-upload__error" : ""}`}>
                      <p>{`Background image${noImageError ? " can't be empty" : ""}`}</p>
                      {
                        imageUrl
                          ? (
                            <div className="file-upload__image-container">
                              <img
                                src={imageUrl}
                                alt="image"
                                className="file-upload__image-preview"
                                onClick={evt => {
                                  evt.preventDefault();
                                }}
                              />
                              <img
                                src="/images/delete.svg"
                                alt="delete"
                                className="file-upload__image-delete"
                                onClick={() => {
                                  setImageUrl("");
                                  setImage(null);
                                }}
                              />
                            </div>
                          )
                          : (
                            <Controller
                              name="document"
                              as={<Upload.Dragger
                                accept=".jpeg,.jpg,.png"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                fileList={[]}
                                beforeUpload={file => {
                                  setNoImageError(false);
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    setCropImageUrl(reader.result);
                                    setIsCropperOpen(true);
                                  };
                                  reader.readAsDataURL(file);

                                  return false;
                                }}
                              >
                                <div className="file-upload__text-container">
                                  <div>Drag & Drop</div>
                                  <div>or</div>
                                  <div className="file-upload__button">Upload</div>
                                </div>
                              </Upload.Dragger>}
                              control={control}
                            />
                          )
                      }
                      <p>Supports: .jpeg, .jpg, .png file formats</p>
                      <p>Recommended: minimum 995x435 px</p>
                      <Modal
                        className="modal"
                        visible={isCropperOpen}
                        onCancel={() => setIsCropperOpen(false)}
                        footer={null}
                        width="100%"
                        destroyOnClose={true}
                        closable={false}
                      >
                        <CropEventImageModalBody
                          cropImageUrl={cropImageUrl}
                          onCancel={() => setIsCropperOpen(false)}
                          onCrop={(croppedImageUrl, croppedImage) => {
                            setImageUrl(croppedImageUrl);
                            setIsCropperOpen(false);
                            setImage(croppedImage);
                          }}
                        />
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="form-section__field">
                <InputWithLabel
                  name="link"
                  className="form-section__field"
                  control={control}
                  id="link"
                  onChange={([e]) => {
                    clearErrorHandler();

                    return e.target.value;
                  }}
                  label="Link"
                  defaultValue={event.link}
                  error={errors.link}
                  placeholder="Enter Link"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="form-section__field">
                <TextAreaWithLabel
                  name="description"
                  control={control}
                  id="description"
                  label="Description"
                  error={errors.description}
                  placeholder="Add description"
                  rows={3}
                  maxLength={200}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  htmlType="submit"
                  disabled={isProcessing}
                  className="button button--default"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool,
  places: PropTypes.array.isRequired,
  clearError: PropTypes.func.isRequired,
  clearEvent: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getPlaces: PropTypes.func.isRequired
};

export default connect(
  state => ({
    event: state.admin.events.singleEvent,
    error: state.admin.events.error,
    isProcessing: state.admin.events.processing,
    requestSuccess: state.admin.events.requestSuccess
  }),
  {
    getEvent: getEventRequest,
    createEvent: createEventRequest,
    updateEvent: updateEventRequest,
    clearError: clearEventErrorAction,
    clearEvent: clearEventAction,
    getPlaces: getPlacesRequest
  }
)(Event);
