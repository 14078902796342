import * as types from "./eventsActionsTypes";

export const getEventsListRequest = (data) => {
  return { type: types.ADMIN_GET_EVENTS_LIST_REQUEST, data };
};

export const getEventRequest = (data) => {
  return { type: types.ADMIN_GET_EVENT_REQUEST, data };
};

export const createEventRequest = (data) => {
  return { type: types.ADMIN_CREATE_EVENT_REQUEST, data };
};

export const updateEventRequest = (data) => {
  return { type: types.ADMIN_UPDATE_EVENT_REQUEST, data };
};

export const deleteEventRequest = (data) => {
  return { type: types.ADMIN_DELETE_EVENT_REQUEST, data };
};

export const clearEventAction = () => {
  return { type: types.ADMIN_CLEAR_EVENT };
};

export const clearEventErrorAction = () => {
  return { type: types.ADMIN_CLEAR_EVENT_ERROR };
};
