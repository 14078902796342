import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Row, Select } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { itemRender } from "../../../helpers/pagination";
import { round } from "../../../helpers/common";
import { LISTING_TYPE_B2B, LISTING_TYPE_ORGANIC } from "../../constants";
import {
  getListingsForAccountingCsvExportUrlRequest,
  getListingsForAccountingRequest
} from "../../../redux/admin/finance/financeActions";
import TopScrollTable from "../../shared/TopScrollTable/TopScrollTable";

export const FinancialAccounting = ({
  pagination,
  isProcessing,
  listings,
  getListingsForAccounting,
  getCsvExportUrl,
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    sort: "-purchasedAt",
  });

  useEffect(() => {
    getListingsForAccounting(paginationObj);
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const delayedFilterByDate = debounce(dates => {
    setPaginationObj({
      ...paginationObj,
      startDate: dates ? moment(dates[0]).format("YYYY-MM-DD") : null,
      endDate: dates ? moment(dates[1]).format("YYYY-MM-DD") : null,
      page: 1
    });
  }, 300);

  return (
    <Row className="admin-financial-accounting">
      <Col span={24}>
        <Row justify="space-between" align="top">
          <Col span={16} className="feedback-list__filters admin-financial-accounting__filters">
            <Select
              className="feedback-list__filter"
              defaultValue={paginationObj.pageSize}
              onChange={value => {
                setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
              }}
            >
              <Select.Option value={10}>Show 10</Select.Option>
              <Select.Option value={20}>Show 20</Select.Option>
              <Select.Option value={30}>Show 30</Select.Option>
            </Select>
            <DatePicker.RangePicker
              className="feedback-list__filter admin-financial-accounting__date-range-picker"
              format="DD-MM-YYYY"
              onChange={value => delayedFilterByDate(value)}
            />
          </Col>
          <Col span={4} className="admin-financial-accounting__download-csv">
            <Button
              className="button button--default"
              onClick={() => getCsvExportUrl(paginationObj)}
            >Download .csv</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TopScrollTable
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={listings.map(listing => {
                const originalPrice = parseFloat(listing.originalPrice);
                const sellingPrice = parseFloat(listing.sellingPrice);

                const totalFee = sellingPrice * parseFloat(listing.fee || 0);

                const systemFee = round(totalFee / 100, 2);
                const systemVat = round(((totalFee / 100) * parseFloat(listing.vat || 0)) / 100, 2);

                let addedToMangoPayFeesWallet = 0;
                let addedToSellerWallet = 0;
                if (listing.isPaidInFull) {
                  addedToMangoPayFeesWallet = round(systemFee + systemVat, 2);
                  addedToSellerWallet = round(sellingPrice - systemFee - systemVat, 2);
                }

                let sellerCharged = 0;
                let addedToSystemWallet = 0;
                if (!listing.isPaidInFull) {
                  sellerCharged = round((originalPrice - sellingPrice) + systemFee + systemVat, 2);
                  addedToSystemWallet = round(sellingPrice + sellerCharged, 2);
                }

                return {
                  key: listing.slug,
                  purchasedAt: listing.purchasedAt ? moment.utc(listing.purchasedAt).format("DD MMM YYYY") : "",
                  id: listing.id,
                  paymentStatus: listing.type === LISTING_TYPE_ORGANIC ? (listing.isPaidInFull ? "PP" : "PAP") : "",
                  buyerName: listing.Buyer ? `${listing.Buyer.firstName} ${listing.Buyer.lastName}` : "",
                  sellerName: listing.type === LISTING_TYPE_B2B ? listing.provider : `${listing.Seller.firstName} ${listing.Seller.lastName}`,
                  currency: listing.Seller ? listing.Seller.currency : listing.currency,
                  originalPrice: (+listing.originalPrice).toFixed(2),
                  sellingPrice: (+listing.sellingPrice).toFixed(2),
                  fee: (+systemFee).toFixed(2),
                  vat: (+systemVat).toFixed(2),
                  buyerCharged: (+listing.sellingPrice).toFixed(2),
                  sellerCharged: (+sellerCharged).toFixed(2),
                  addedToMangoPayFeesWallet: (+addedToMangoPayFeesWallet).toFixed(2),
                  addedToSellerWallet: (+addedToSellerWallet).toFixed(2),
                  addedToSystemWallet: (+addedToSystemWallet).toFixed(2),
                }
              })}
              columns={[
                {
                  title: "Date of Sale",
                  dataIndex: "purchasedAt",
                  key: "purchasedAt",
                  defaultSortOrder: "descend",
                  sorter: (prevItem, nextItem) => {
                    return moment(prevItem.purchasedAt).unix() - moment(nextItem.purchasedAt).unix();
                  },
                  sortDirections: ["descend", "ascend"],
                  width: 145
                },
                {
                  title: "CIS Reference",
                  dataIndex: "id",
                  key: "id",
                  sorter: true,
                },
                {
                  title: "Booking Type",
                  dataIndex: "paymentStatus",
                  key: "paymentStatus",
                  sorter: false,
                },
                {
                  title: "Seller Name",
                  dataIndex: "sellerName",
                  key: "sellerName",
                  sorter: true,
                  width: 120
                },
                {
                  title: "Buyer Name",
                  dataIndex: "buyerName",
                  key: "buyerName",
                  sorter: true,
                  width: 120
                },
                {
                  title: "Currency",
                  dataIndex: "currency",
                  key: "currency",
                  sorter: false
                },
                {
                  title: "Original Purchase Price",
                  dataIndex: "originalPrice",
                  key: "originalPrice",
                  sorter: true
                },
                {
                  title: "Selling Price",
                  dataIndex: "sellingPrice",
                  key: "sellingPrice",
                  sorter: true
                },
                {
                  title: "CIS Commission",
                  dataIndex: "fee",
                  key: "fee",
                  sorter: false
                },
                {
                  title: "VAT Amount",
                  dataIndex: "vat",
                  key: "vat",
                  sorter: false
                },
                {
                  title: "Buyer Charged",
                  dataIndex: "buyerCharged",
                  key: "buyerCharged",
                  sorter: false
                },
                {
                  title: "Seller Charged",
                  dataIndex: "sellerCharged",
                  key: "sellerCharged",
                  sorter: false
                },
                {
                  title: "Mangopay CIS A/C",
                  dataIndex: "addedToMangoPayFeesWallet",
                  key: "addedToMangoPayFeesWallet",
                  sorter: false
                },
                {
                  title: "Mangopay Seller wallet",
                  dataIndex: "addedToSellerWallet",
                  key: "addedToSellerWallet",
                  sorter: false
                },
                {
                  title: "Mangopay Professional account",
                  dataIndex: "addedToSystemWallet",
                  key: "addedToSystemWallet",
                  sorter: false
                },
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

FinancialAccounting.propTypes = {
  listings: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getListingsForAccounting: PropTypes.func.isRequired,
  getCsvExportUrl: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    listings: state.admin.finance.listings.data,
    pagination: {
      current: state.admin.finance.listings.curPage,
      total: state.admin.finance.listings.total,
      pageSize: state.admin.finance.listings.perPage
    },
    isProcessing: state.admin.finance.processing,
    error: state.admin.finance.error
  }),
  {
    getListingsForAccounting: getListingsForAccountingRequest,
    getCsvExportUrl: getListingsForAccountingCsvExportUrlRequest
  }
)(FinancialAccounting);
