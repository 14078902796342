import { all, put, call, takeLatest } from "redux-saga/effects";

import events from "../../api/events";
import * as types from "./eventsActionsTypes";

function *getEvents ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(events.getEvents, data);
    yield put({ type: types.GET_EVENTS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_EVENTS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_EVENTS_REQUEST, getEvents),
  ])
}
