export const ADMIN_SHOW_LOADING = "ADMIN_SHOW_LOADING";
export const ADMIN_HIDE_LOADING = "ADMIN_HIDE_LOADING";

export const ADMIN_GET_EVENTS_LIST_REQUEST = "ADMIN_GET_EVENTS_LIST_REQUEST";
export const ADMIN_GET_EVENTS_LIST_REQUEST_SUCCESS = "ADMIN_GET_EVENTS_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_EVENTS_LIST_REQUEST_ERROR = "ADMIN_GET_EVENTS_LIST_REQUEST_ERROR";

export const ADMIN_GET_EVENT_REQUEST = "ADMIN_GET_EVENT_REQUEST";
export const ADMIN_GET_EVENT_REQUEST_SUCCESS = "ADMIN_GET_EVENT_REQUEST_SUCCESS";
export const ADMIN_GET_EVENT_REQUEST_ERROR = "ADMIN_GET_EVENT_REQUEST_ERROR";

export const ADMIN_CREATE_EVENT_REQUEST = "ADMIN_CREATE_EVENT_REQUEST";
export const ADMIN_CREATE_EVENT_REQUEST_SUCCESS = "ADMIN_CREATE_EVENT_REQUEST_SUCCESS";
export const ADMIN_CREATE_EVENT_REQUEST_ERROR = "ADMIN_CREATE_EVENT_REQUEST_ERROR";

export const ADMIN_UPDATE_EVENT_REQUEST = "ADMIN_UPDATE_EVENT_REQUEST";
export const ADMIN_UPDATE_EVENT_REQUEST_SUCCESS = "ADMIN_UPDATE_EVENT_REQUEST_SUCCESS";
export const ADMIN_UPDATE_EVENT_REQUEST_ERROR = "ADMIN_UPDATE_EVENT_REQUEST_ERROR";

export const ADMIN_DELETE_EVENT_REQUEST = "ADMIN_DELETE_EVENT_REQUEST";
export const ADMIN_DELETE_EVENT_REQUEST_SUCCESS = "ADMIN_DELETE_EVENT_REQUEST_SUCCESS";
export const ADMIN_DELETE_EVENT_REQUEST_ERROR = "ADMIN_DELETE_EVENT_REQUEST_ERROR";

export const ADMIN_CLEAR_EVENT = "ADMIN_CLEAR_EVENT";
export const ADMIN_CLEAR_EVENT_ERROR = "ADMIN_CLEAR_EVENT_ERROR";
