import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router"

import Menu from "../shared/Menu";

const AdminSidebar = ({ location }) => {
  const path = location.pathname.split("/");
  let active = path[path.length - 1];

  if (["about-us", "privacy-policy", "terms-and-conditions", "cookie-policy"].includes(active)) {
    active = "website-management";
  } else if (path[2] === "blog") {
    active = "blog";
  } else if (path[2] === "faq") {
    active = "faq";
  } else if (path[2] === "hotels") {
    active = "hotels";
  }

  const items = [
    {
      key: "dashboard",
      url: "/admin/dashboard",
      label: "Dashboard"
    }, {
      key: "listings",
      url: "/admin/listings",
      label: "Listings"
    }, {
      key: "disputes",
      url: "/admin/disputes",
      label: "Disputes"
    }, {
      key: "notification-center",
      url: "/admin/notification-center",
      label: "Notification Center"
    }, {
      key: "feedback",
      url: "/admin/feedback",
      label: "Feedback"
    }, {
      key: "finance-settings",
      url: "/admin/finance-settings",
      label: "Finance Settings"
    }, {
      key: "financial-accounting",
      url: "/admin/financial-accounting",
      label: "Financial Accounting"
    }, {
      key: "user-management",
      url: "/admin/user-management",
      label: "User Management"
    }, {
      key: "website-management",
      url: "/admin/website-management/home-page",
      label: "Website Management"
    }, {
      key: "blog",
      url: "/admin/blog/articles",
      label: "Blog"
    }, {
      key: "events",
      url: "/admin/events",
      label: "Events"
    }, {
      key: "faq",
      url: "/admin/faq/questions",
      label: "FAQ"
    }, {
      key: "subscriptions",
      url: "/admin/subscriptions",
      label: "Subscriptions"
    }, {
      key: "hotels",
      url: "/admin/hotels",
      label: "Hotels"
    }, {
      key: "reservations",
      url: "/admin/reservations",
      label: "Reservations"
    }
  ];

  return (
    <div className="admin-sidebar">
      <Menu
        items={items}
        mode="vertical-left"
        selected={[active]}
      />
    </div>
  );
};

AdminSidebar.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(AdminSidebar);
