import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Select, DatePicker, Input, Switch, Button } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";

import { getListingStatus } from "../../../helpers/common";
import {
  getCsvExportUrlRequest,
  getListingsRequest,
  updateListingRequest
} from "../../../redux/admin/listings/listingsActions";
import { itemRender } from "../../../helpers/pagination";
import { LISTING_TYPE_B2B, LISTING_TYPE_ORGANIC } from "../../constants";
import TopScrollTable from "../../shared/TopScrollTable/TopScrollTable";

const ListingList = ({
  getListings,
  getCsvExportUrl,
  listings,
  pagination,
  isProcessing,
  updateListing
}) => {
  const [dateFilterType, setDateFilterType] = useState("createdAt");
  const [paginationObj, setPaginationObj] = useState({
    country: null,
    pageSize: 10,
    status: null,
    sort: "-createdAt",
    type: null
  });

  useEffect(() => {
    getListings({
      ...paginationObj,
      dateFilterType
    });
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByStatus = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, status: value });
  };

  const handleDateTypeChange = checked => {
    setDateFilterType(checked ? "checkInDate" : "createdAt");

    if (paginationObj.startDate && paginationObj.endDate) {
      setPaginationObj({
        ...paginationObj,
        page: 1
      });
    }
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(dates => {
    setPaginationObj({
      ...paginationObj,
      startDate: dates ? moment(dates[0]).format("YYYY-MM-DD") : null,
      endDate: dates ? moment(dates[1]).format("YYYY-MM-DD") : null,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderLink = (text, record) => (
    <Link to={`/listings/${record.key}`} className="feedback-list__link" target="_blank">
      {text}
    </Link>
  );

  const renderPublishStatusSelect = (text, record) => (
    <span>
      <Select
        id={`status-${record.id}`}
        disabled={isProcessing || record.status === "sold" || record.status === "suspended"}
        value={text}
        onChange={data => {
          updateListing({ id: record.id, publishStatus: data });
        }}
      >
        <Select.Option value="active">Active</Select.Option>
        <Select.Option value="suspended">Suspended</Select.Option>
      </Select>
    </span>
  );

  const renderStatusSelect = (text, record) => {
    return (
      <span>
        <Select
          id={`status-${record.id}`}
          disabled={isProcessing || !["pending", "published", "active"].includes(record.status)}
          value={text}
          onChange={data => {
            updateListing({ id: record.id, status: data });
          }}
        >
          {
            record.status === "active" ? (
              <Select.Option value="active">Active</Select.Option>
            ) : (
              <Select.Option value="published">Active</Select.Option>
            )
          }
          <Select.Option value="pending">Pending</Select.Option>
          {["published", "active"].includes(text) && <Select.Option value="sold">Sold</Select.Option>}
        </Select>
      </span>
    );
  };

  return (
    <Row className="admin-listings">
      <Col span={24}>
        <Row justify="space-between" align="top">
          <Col span={16} className="feedback-list__filters admin-listings__filters">
            <Select
              className="feedback-list__filter"
              defaultValue={paginationObj.pageSize}
              onChange={value => {
                setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
              }}
            >
              <Select.Option value={10}>Show 10</Select.Option>
              <Select.Option value={20}>Show 20</Select.Option>
              <Select.Option value={30}>Show 30</Select.Option>
            </Select>
            <Select
              className="feedback-list__filter user-management-list__filter-category"
              defaultValue={paginationObj.status}
              onChange={handleFilterByStatus}
            >
              <Select.Option value={null}>Any Status</Select.Option>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="published">Active</Select.Option>
              <Select.Option value="sold">Sold</Select.Option>
              <Select.Option value="expired">Expired</Select.Option>
            </Select>
            <Select
              className="feedback-list__filter"
              defaultValue={paginationObj.country}
              onChange={value => {
                setPaginationObj({ ...paginationObj, page: 1, country: value });
              }}
            >
              <Select.Option value={null}>All</Select.Option>
              <Select.Option value="GB">Great Britain</Select.Option>
              <Select.Option value="US">United States</Select.Option>
            </Select>
            <Select
              className="feedback-list__filter admin-listings__filter-type"
              defaultValue={paginationObj.type}
              onChange={value => {
                setPaginationObj({ ...paginationObj, page: 1, type: value });
              }}
            >
              <Select.Option value={null}>Any Source</Select.Option>
              <Select.Option value="organic">Organic</Select.Option>
              <Select.Option value="b2b">B2B</Select.Option>
            </Select>
            <DatePicker.RangePicker
              className="feedback-list__filter admin-listings__date-range-picker"
              format="DD-MM-YYYY"
              onChange={value => delayedFilterByDate(value)}
            />
            <Switch
              unCheckedChildren="Listed Date"
              checkedChildren="Commencement Date"
              className="admin-listings__date-type"
              onChange={handleDateTypeChange}
            />
          </Col>
          <Col span={4}>
            <Input.Search
              onChange={event => delayedSearch(event.target.value)}
              onSearch={handleSearch}
              placeholder="Search"
              className="feedback-list__search"
            />
          </Col>
          <Col span={4} className="admin-listings__download-csv">
            <Button
              className="button button--default"
              onClick={() => getCsvExportUrl(paginationObj)}
            >Download .csv</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TopScrollTable
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={listings.map(listing => {
                let listingStatus = getListingStatus(listing.status, listing.checkInDate);
                if (listingStatus !== "sold" && listing.Seller && listing.Seller.status === "suspended") {
                  listingStatus = "suspended";
                }

                return {
                  key: listing.slug,
                  hotelName: listing.Hotel.name,
                  hotelPostalCode: listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress.postalCode : "",
                  id: listing.id,
                  status: listingStatus,
                  buyerName: listing.Buyer ? `${listing.Buyer.firstName} ${listing.Buyer.lastName}` : "",
                  sellerName: listing.type === LISTING_TYPE_B2B ? listing.provider : `${listing.Seller.firstName} ${listing.Seller.lastName}`,
                  createdAt: moment(listing.createdAt).format("DD MMM YYYY"),
                  checkInDate: moment(listing.checkInDate).format("DD MMM YYYY"),
                  serviceFee: (+listing.serviceFee).toFixed(2),
                  publishStatus: listing.publishStatus,
                  provider: listing.type === LISTING_TYPE_ORGANIC ? listing.provider : "",
                  type: listing.type,
                  paymentStatus: listing.type === LISTING_TYPE_ORGANIC ? (listing.isPaidInFull ? "Paid in full" : "Payment at the property") : "",
                  currency: listing.Seller ? listing.Seller.currency : listing.currency,
                  bookingReference: listing.bookingReference,
                  bookingPin: listing.bookingPin,
                  bookingName: listing.bookingName,
                  reasonOfSale: listing.reasonOfSale,
                  areLocalFeesPayable: listing.areLocalFeesPayable ? "Yes" : "No"
                }
              })}
              columns={[
                {
                  title: "Title",
                  dataIndex: "hotelName",
                  key: "hotelName",
                  render: renderLink,
                  sorter: true,
                  width: 150
                },
                {
                  title: "Payment status",
                  dataIndex: "paymentStatus",
                  key: "paymentStatus",
                  sorter: false,
                  width: 120
                },
                {
                  title: "Postcode",
                  dataIndex: "hotelPostalCode",
                  key: "hotelPostalCode",
                  sorter: true
                },
                {
                  title: "Ref. no.",
                  dataIndex: "id",
                  key: "id",
                  sorter: true
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  sorter: true,
                  render: renderStatusSelect,
                  className: "user-management-list__item-status",
                },
                {
                  title: "Buyer",
                  dataIndex: "buyerName",
                  key: "buyerName",
                  sorter: true
                },
                {
                  title: "Seller",
                  dataIndex: "sellerName",
                  key: "sellerName",
                  sorter: true
                },
                {
                  title: "Provider",
                  dataIndex: "provider",
                  key: "provider",
                  sorter: true
                },
                {
                  title: "Listed",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                  sortDirections: ["descend", "ascend"],
                  width: 145
                },
                {
                  title: "Commencement",
                  dataIndex: "checkInDate",
                  key: "checkInDate",
                  sorter: (a, b) => moment(a.checkInDate).unix() - moment(b.checkInDate).unix(),
                  sortDirections: ["descend", "ascend"],
                  width: 145
                },
                {
                  title: "PlansChange fees",
                  dataIndex: "serviceFee",
                  key: "serviceFee",
                  sorter: true,
                  render: (value, record) => `${record.currency === "USD" ? "$" : "£"} ${value}`
                },
                {
                  title: "Active",
                  dataIndex: "publishStatus",
                  key: "publishStatus",
                  defaultSortOrder: "",
                  sorter: true,
                  sortDirections: ["descend", "ascend"],
                  className: "user-management-list__item-status",
                  render: renderPublishStatusSelect
                },
                {
                  title: "Booking reference",
                  dataIndex: "bookingReference",
                  key: "bookingReference",
                  width: 130,
                },
                {
                  title: "Booking PIN",
                  dataIndex: "bookingPin",
                  key: "bookingPin",
                  width: 100,
                },
                {
                  title: "Original booking name",
                  dataIndex: "bookingName",
                  key: "bookingReference",
                  width: 150,
                },
                {
                  title: "Reason for resale",
                  dataIndex: "reasonOfSale",
                  key: "reasonOfSale",
                  width: 150,
                },
                {
                  title: "Local fees",
                  dataIndex: "areLocalFeesPayable",
                  key: "areLocalFeesPayable",
                  width: 100,
                },
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

ListingList.propTypes = {
  listings: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getListings: PropTypes.func.isRequired,
  getCsvExportUrl: PropTypes.func.isRequired,
  updateListing: PropTypes.func.isRequired
};

export default connect(
  state => ({
    listings: state.admin.listings.data,
    pagination: {
      current: state.admin.listings.curPage,
      total: state.admin.listings.total,
      pageSize: state.admin.listings.perPage
    },
    isProcessing: state.admin.listings.processing,
    error: state.admin.listings.error
  }),
  {
    getListings: getListingsRequest,
    getCsvExportUrl: getCsvExportUrlRequest,
    updateListing: updateListingRequest
  }
)(ListingList);
