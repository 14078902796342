import { Table as AntTable } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const TopScrollTable = ({
  loading,
  pagination,
  dataSource,
  columns,
  onChange,
}) => {
  const [totalWidth, setTotalWidth] = useState(0);

  const topScrollRef = useRef(null);
  const tableBodyRef = useRef(null);

  useEffect(() => {
    const tableBody = document.querySelector(".top-scroll-table-container .ant-table-content");

    if (tableBody) {
      tableBodyRef.current = tableBody;

      const handleTableScroll = (e) => {
        if (topScrollRef.current) {
          topScrollRef.current.scrollLeft = e.target.scrollLeft;
        }
      };

      tableBody.addEventListener("scroll", handleTableScroll);

      setTimeout(() => {
        setTotalWidth(tableBody.scrollWidth);
      }, 300);

      return () => {
        tableBody.removeEventListener("scroll", handleTableScroll);
      };
    }
  }, []);

  const handleTopScroll = (e) => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  return (
    <div className="top-scroll-table-container">
      <div
        ref={topScrollRef}
        className="top-scroll-table__top-scroll"
        onScroll={handleTopScroll}
      >
        <div className="top-scroll-table__scroll-body" style={{ width: totalWidth }}/>
      </div>
      <AntTable
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        loading={loading}
        onChange={onChange}
      />
    </div>
  );
}

TopScrollTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TopScrollTable;
