import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Table, DatePicker, Select, Input, Button } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import {
  deleteSubscriptionRequest,
  getSubscriptionsListRequest,
  getCsvExportUrlRequest
} from "../../../redux/admin/subscriptions/subscriptionsActions";
import { itemRender } from "../../../helpers/pagination";
import { showModalAction } from "../../../redux/general/generalActions";

const SubscriptionsList = ({
  subscriptionsList,
  getSubscriptionsList,
  deleteSubscription,
  pagination,
  isProcessing,
  error,
  showModal,
  getCsvExportUrl
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    sort: "-createdAt"
  });

  useEffect(() => {
    getSubscriptionsList(paginationObj);
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderDeleteButton = record => <img
    src="/images/close.svg"
    alt="remove"
    className="feedback-list__delete"
    onClick={() => showModal({
      type: "confirm",
      title: "Are You sure you would like to delete subscription?",
      params: {
        isProcessing,
        error,
        confirmAction: () => deleteSubscription({ id: record.key })
      }
    })}
  />;

  return (
    <React.Fragment>
      <Row className="feedback-list">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="top">
                <Col span={14} className="feedback-list__filters">
                  <Select
                    className="feedback-list__filter events-list__filter-size"
                    defaultValue={paginationObj.pageSize}
                    onChange={value => {
                      setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                    }}
                  >
                    <Select.Option value={10}>Show 10</Select.Option>
                    <Select.Option value={20}>Show 20</Select.Option>
                    <Select.Option value={30}>Show 30</Select.Option>
                  </Select>
                  <DatePicker
                    className="feedback-list__filter"
                    format="DD-MM-YYYY"
                    onChange={value => delayedFilterByDate(value)}
                  />
                </Col>
                <Col span={6}>
                  <Input.Search
                    onChange={event => delayedSearch(event.target.value)}
                    onSearch={handleSearch}
                    placeholder="Search"
                    className="feedback-list__search"
                  />
                </Col>
                <Col span={4} className="user-management-list__download-csv">
                  <Button
                    className="button button--default"
                    onClick={() => getCsvExportUrl(paginationObj)}
                  >Download .csv</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isProcessing}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  itemRender: itemRender,
                  showSizeChanger: false
                }}
                dataSource={subscriptionsList.map(subscription => {
                  return {
                    key: subscription.id,
                    email: subscription.email,
                    createdAt: moment(subscription.createdAt).format("DD MMM YYYY, hh:mmA")
                  }
                })}
                columns={[
                  {
                    title: "Email",
                    dataIndex: "email",
                    key: "email"
                  },
                  {
                    title: "Date/Time",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                    sortDirections: ["descend", "ascend"],
                    width: 200
                  },
                  {
                    title: "",
                    key: "actions",
                    width: 30,
                    render: renderDeleteButton
                  }
                ]}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
};

SubscriptionsList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  subscriptionsList: PropTypes.array.isRequired,
  getSubscriptionsList: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  getCsvExportUrl: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.subscriptions.processing,
    error: state.admin.subscriptions.error,
    subscriptionsList: state.admin.subscriptions.data,
    pagination: {
      current: state.admin.subscriptions.curPage,
      total: state.admin.subscriptions.total,
      pageSize: state.admin.subscriptions.perPage
    }
  }),
  {
    getSubscriptionsList: getSubscriptionsListRequest,
    deleteSubscription: deleteSubscriptionRequest,
    showModal: showModalAction,
    getCsvExportUrl: getCsvExportUrlRequest
  }
)(SubscriptionsList);
