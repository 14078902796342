import * as types from "./eventsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  requestSuccess: false,
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  options: [],
  singleEvent: {
    body: "",
    categories: [],
    coverImageUrl: "",
    title: ""
  }
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.ADMIN_HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_EVENTS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_DELETE_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_GET_EVENT_REQUEST_SUCCESS:
    case types.ADMIN_CREATE_EVENT_REQUEST_SUCCESS:
    case types.ADMIN_UPDATE_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        singleEvent: action.response,
        error: ""
      };
    case types.ADMIN_GET_EVENTS_LIST_REQUEST_ERROR:
    case types.ADMIN_GET_EVENT_REQUEST_ERROR:
    case types.ADMIN_CREATE_EVENT_REQUEST_ERROR:
    case types.ADMIN_UPDATE_EVENT_REQUEST_ERROR:
    case types.ADMIN_DELETE_EVENT_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    case types.ADMIN_CLEAR_EVENT:
      return {
        ...state,
        singleEvent: {
          body: "",
          categories: [],
          coverImageUrl: "",
          title: ""
        }
      };
    case types.ADMIN_CLEAR_EVENT_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default events;
