import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const events = {
  async getEventsList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/events?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createEvent(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/events`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateEvent(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/events/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getEvent(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/events/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteEvent(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/events/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default events;
