const HOST = process.env.REACT_APP_API_HOST;

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const MAPBOX_USERNAME = process.env.REACT_APP_MAPBOX_USERNAME;
export const MAPBOX_STYLE_ID = process.env.REACT_APP_MAPBOX_STYLE_ID;

export const API_URL = `${HOST}/api/v1`;

export const ISO31661ALPHA2 = [
  { code: "GB", name: "United Kingdom", isSupportedInMangoPay: true },
  { code: "US", name: "United States", isSupportedInMangoPay: true },
  { code: "AF", name: "Afghanistan", isSupportedInMangoPay: false },
  { code: "AX", name: "Aland Islands", isSupportedInMangoPay: true },
  { code: "AL", name: "Albania", isSupportedInMangoPay: true },
  { code: "DZ", name: "Algeria", isSupportedInMangoPay: true },
  { code: "AS", name: "American Samoa", isSupportedInMangoPay: true },
  { code: "AD", name: "Andorra", isSupportedInMangoPay: true },
  { code: "AO", name: "Angola", isSupportedInMangoPay: true },
  { code: "AI", name: "Anguilla", isSupportedInMangoPay: true },
  { code: "AQ", name: "Antarctica", isSupportedInMangoPay: true },
  { code: "AG", name: "Antigua and Barbuda", isSupportedInMangoPay: true },
  { code: "AR", name: "Argentina", isSupportedInMangoPay: true },
  { code: "AM", name: "Armenia", isSupportedInMangoPay: true },
  { code: "AW", name: "Aruba", isSupportedInMangoPay: true },
  { code: "SH", name: "Ascension Island/St. Helena", isSupportedInMangoPay: true },
  { code: "AU", name: "Australia", isSupportedInMangoPay: true },
  { code: "AT", name: "Austria", isSupportedInMangoPay: true },
  { code: "AZ", name: "Azerbaijan", isSupportedInMangoPay: true },
  { code: "BS", name: "Bahamas", isSupportedInMangoPay: true },
  { code: "BH", name: "Bahrain", isSupportedInMangoPay: true },
  { code: "BD", name: "Bangladesh", isSupportedInMangoPay: true },
  { code: "BB", name: "Barbados", isSupportedInMangoPay: true },
  { code: "BY", name: "Belarus", isSupportedInMangoPay: true },
  { code: "BE", name: "Belgium", isSupportedInMangoPay: true },
  { code: "BZ", name: "Belize", isSupportedInMangoPay: true },
  { code: "BJ", name: "Benin", isSupportedInMangoPay: true },
  { code: "BM", name: "Bermuda", isSupportedInMangoPay: true },
  { code: "BT", name: "Bhutan", isSupportedInMangoPay: true },
  { code: "BO", name: "Bolivia", isSupportedInMangoPay: true },
  { code: "BQ", name: "Bonaire", isSupportedInMangoPay: true },
  { code: "BA", name: "Bosnia and Herzegovina", isSupportedInMangoPay: true },
  { code: "BW", name: "Botswana", isSupportedInMangoPay: true },
  { code: "BV", name: "Bouvet Island", isSupportedInMangoPay: true },
  { code: "BR", name: "Brazil", isSupportedInMangoPay: true },
  { code: "IO", name: "British Indian Ocean Territory", isSupportedInMangoPay: true },
  { code: "VG", name: "British Virgin Islands", isSupportedInMangoPay: true },
  { code: "BN", name: "Brunei", isSupportedInMangoPay: true },
  { code: "BG", name: "Bulgaria", isSupportedInMangoPay: true },
  { code: "BF", name: "Burkina Faso", isSupportedInMangoPay: true },
  { code: "BI", name: "Burundi", isSupportedInMangoPay: true },
  { code: "KH", name: "Cambodia", isSupportedInMangoPay: true },
  { code: "CM", name: "Cameroon", isSupportedInMangoPay: true },
  { code: "CA", name: "Canada", isSupportedInMangoPay: true },
  { code: "CV", name: "Cape Verde", isSupportedInMangoPay: true },
  { code: "KY", name: "Cayman Islands", isSupportedInMangoPay: true },
  { code: "CF", name: "Central African Republic", isSupportedInMangoPay: true },
  { code: "TD", name: "Chad", isSupportedInMangoPay: true },
  { code: "CL", name: "Chile", isSupportedInMangoPay: true },
  { code: "CN", name: "China", isSupportedInMangoPay: true },
  { code: "CX", name: "Christmas Island", isSupportedInMangoPay: true },
  { code: "CC", name: "Cocos (Keeling) Islands", isSupportedInMangoPay: true },
  { code: "CO", name: "Colombia", isSupportedInMangoPay: true },
  { code: "KM", name: "Comoros", isSupportedInMangoPay: true },
  { code: "CG", name: "Congo", isSupportedInMangoPay: true },
  { code: "CD", name: "Congo, the Democratic Republic of the", isSupportedInMangoPay: true },
  { code: "CK", name: "Cook Islands", isSupportedInMangoPay: true },
  { code: "CR", name: "Costa Rica", isSupportedInMangoPay: true },
  { code: "HR", name: "Croatia", isSupportedInMangoPay: true },
  { code: "CU", name: "Cuba", isSupportedInMangoPay: true },
  { code: "CW", name: "Curacao", isSupportedInMangoPay: true },
  { code: "CY", name: "Cyprus", isSupportedInMangoPay: true },
  { code: "CZ", name: "Czech Republic", isSupportedInMangoPay: true },
  { code: "DK", name: "Denmark", isSupportedInMangoPay: true },
  { code: "DJ", name: "Djibouti", isSupportedInMangoPay: true },
  { code: "DM", name: "Dominica", isSupportedInMangoPay: true },
  { code: "DO", name: "Dominican Republic", isSupportedInMangoPay: true },
  { code: "EC", name: "Ecuador", isSupportedInMangoPay: true },
  { code: "EG", name: "Egypt", isSupportedInMangoPay: true },
  { code: "SV", name: "El Salvador", isSupportedInMangoPay: true },
  { code: "GQ", name: "Equatorial Guinea", isSupportedInMangoPay: true },
  { code: "ER", name: "Eritrea", isSupportedInMangoPay: true },
  { code: "EE", name: "Estonia", isSupportedInMangoPay: true },
  { code: "ET", name: "Ethiopia", isSupportedInMangoPay: true },
  { code: "FK", name: "Falkland Islands", isSupportedInMangoPay: true },
  { code: "FO", name: "Faroe Islands", isSupportedInMangoPay: true },
  { code: "FJ", name: "Fiji", isSupportedInMangoPay: true },
  { code: "FI", name: "Finland", isSupportedInMangoPay: true },
  { code: "FR", name: "France", isSupportedInMangoPay: true },
  { code: "GF", name: "French Guiana", isSupportedInMangoPay: true },
  { code: "PF", name: "French Polynesia", isSupportedInMangoPay: true },
  { code: "TF", name: "French Southern Territories", isSupportedInMangoPay: true },
  { code: "GA", name: "Gabon", isSupportedInMangoPay: true },
  { code: "GM", name: "Gambia", isSupportedInMangoPay: true },
  { code: "GE", name: "Georgia", isSupportedInMangoPay: true },
  { code: "DE", name: "Germany", isSupportedInMangoPay: true },
  { code: "GH", name: "Ghana", isSupportedInMangoPay: true },
  { code: "GI", name: "Gibraltar", isSupportedInMangoPay: true },
  { code: "GR", name: "Greece", isSupportedInMangoPay: true },
  { code: "GL", name: "Greenland", isSupportedInMangoPay: true },
  { code: "GD", name: "Grenada", isSupportedInMangoPay: true },
  { code: "GP", name: "Guadeloupe", isSupportedInMangoPay: true },
  { code: "GU", name: "Guam", isSupportedInMangoPay: true },
  { code: "GT", name: "Guatemala", isSupportedInMangoPay: true },
  { code: "GG", name: "Guernsey", isSupportedInMangoPay: true },
  { code: "GN", name: "Guinea", isSupportedInMangoPay: true },
  { code: "GW", name: "Guinea-Bissau", isSupportedInMangoPay: true },
  { code: "GY", name: "Guyana", isSupportedInMangoPay: true },
  { code: "HT", name: "Haiti", isSupportedInMangoPay: true },
  { code: "HM", name: "Heard Island and McDonald Islands", isSupportedInMangoPay: true },
  { code: "VA", name: "Holy See (Vatican City State)", isSupportedInMangoPay: true },
  { code: "HN", name: "Honduras", isSupportedInMangoPay: true },
  { code: "HK", name: "Hong Kong", isSupportedInMangoPay: true },
  { code: "HU", name: "Hungary", isSupportedInMangoPay: true },
  { code: "IS", name: "Iceland", isSupportedInMangoPay: true },
  { code: "IN", name: "India", isSupportedInMangoPay: true },
  { code: "ID", name: "Indonesia", isSupportedInMangoPay: true },
  { code: "IR", name: "Iran", isSupportedInMangoPay: true },
  { code: "IQ", name: "Iraq", isSupportedInMangoPay: true },
  { code: "IE", name: "Ireland", isSupportedInMangoPay: true },
  { code: "IM", name: "Isle of Man", isSupportedInMangoPay: true },
  { code: "IL", name: "Israel", isSupportedInMangoPay: true },
  { code: "IT", name: "Italy", isSupportedInMangoPay: true },
  { code: "CI", name: "Ivory Coast", isSupportedInMangoPay: true },
  { code: "JM", name: "Jamaica", isSupportedInMangoPay: true },
  { code: "JP", name: "Japan", isSupportedInMangoPay: true },
  { code: "JE", name: "Jersey", isSupportedInMangoPay: true },
  { code: "JO", name: "Jordan", isSupportedInMangoPay: true },
  { code: "KZ", name: "Kazakhstan", isSupportedInMangoPay: true },
  { code: "KE", name: "Kenya", isSupportedInMangoPay: true },
  { code: "KI", name: "Kiribati", isSupportedInMangoPay: true },
  { code: "KP", name: "Korea, Democratic Peoples Republic", isSupportedInMangoPay: true },
  { code: "KR", name: "Korea, Republic Of", isSupportedInMangoPay: true },
  { code: "KW", name: "Kuwait", isSupportedInMangoPay: true },
  { code: "KG", name: "Kyrgyzstan", isSupportedInMangoPay: true },
  { code: "LA", name: "Lao People's Democratic Republic", isSupportedInMangoPay: true },
  { code: "LV", name: "Latvia", isSupportedInMangoPay: true },
  { code: "LB", name: "Lebanon", isSupportedInMangoPay: true },
  { code: "LS", name: "Lesotho", isSupportedInMangoPay: true },
  { code: "LR", name: "Liberia", isSupportedInMangoPay: true },
  { code: "LY", name: "Libya", isSupportedInMangoPay: true },
  { code: "LI", name: "Liechtenstein", isSupportedInMangoPay: true },
  { code: "LT", name: "Lithuania", isSupportedInMangoPay: true },
  { code: "LU", name: "Luxembourg", isSupportedInMangoPay: true },
  { code: "MO", name: "Macao", isSupportedInMangoPay: true },
  { code: "MG", name: "Madagascar", isSupportedInMangoPay: true },
  { code: "MW", name: "Malawi", isSupportedInMangoPay: true },
  { code: "MY", name: "Malaysia", isSupportedInMangoPay: true },
  { code: "MV", name: "Maldives", isSupportedInMangoPay: true },
  { code: "ML", name: "Mali", isSupportedInMangoPay: true },
  { code: "MT", name: "Malta", isSupportedInMangoPay: true },
  { code: "MH", name: "Marshall Islands", isSupportedInMangoPay: true },
  { code: "MQ", name: "Martinique", isSupportedInMangoPay: true },
  { code: "MR", name: "Mauritania", isSupportedInMangoPay: true },
  { code: "MU", name: "Mauritius", isSupportedInMangoPay: true },
  { code: "YT", name: "Mayotte", isSupportedInMangoPay: true },
  { code: "MX", name: "Mexico", isSupportedInMangoPay: true },
  { code: "FM", name: "Micronesia", isSupportedInMangoPay: true },
  { code: "MD", name: "Moldova Republic Of", isSupportedInMangoPay: true },
  { code: "MC", name: "Monaco", isSupportedInMangoPay: true },
  { code: "MN", name: "Mongolia", isSupportedInMangoPay: true },
  { code: "ME", name: "Montenegro", isSupportedInMangoPay: true },
  { code: "MS", name: "Montserrat", isSupportedInMangoPay: true },
  { code: "MA", name: "Morocco", isSupportedInMangoPay: true },
  { code: "MZ", name: "Mozambique", isSupportedInMangoPay: true },
  { code: "MM", name: "Myanmar", isSupportedInMangoPay: true },
  { code: "NA", name: "Namibia", isSupportedInMangoPay: true },
  { code: "NR", name: "Nauru", isSupportedInMangoPay: true },
  { code: "NP", name: "Nepal", isSupportedInMangoPay: true },
  { code: "NL", name: "Netherlands", isSupportedInMangoPay: true },
  { code: "NC", name: "New Caledonia", isSupportedInMangoPay: true },
  { code: "NZ", name: "New Zealand", isSupportedInMangoPay: true },
  { code: "NI", name: "Nicaragua", isSupportedInMangoPay: true },
  { code: "NE", name: "Niger", isSupportedInMangoPay: true },
  { code: "NG", name: "Nigeria", isSupportedInMangoPay: true },
  { code: "NU", name: "Niue", isSupportedInMangoPay: true },
  { code: "NF", name: "Norfolk Island", isSupportedInMangoPay: true },
  { code: "MP", name: "Northern Mariana Islands", isSupportedInMangoPay: true },
  { code: "MK", name: "North Macedonia, Republic of", isSupportedInMangoPay: true },
  { code: "NO", name: "Norway", isSupportedInMangoPay: true },
  { code: "OM", name: "Oman", isSupportedInMangoPay: true },
  { code: "PK", name: "Pakistan", isSupportedInMangoPay: true },
  { code: "PW", name: "Palau", isSupportedInMangoPay: true },
  { code: "PA", name: "Panama", isSupportedInMangoPay: true },
  { code: "PG", name: "Papua New Guinea", isSupportedInMangoPay: true },
  { code: "PY", name: "Paraguay", isSupportedInMangoPay: true },
  { code: "PE", name: "Peru", isSupportedInMangoPay: true },
  { code: "PH", name: "Philippines", isSupportedInMangoPay: true },
  { code: "PN", name: "Pitcairn", isSupportedInMangoPay: true },
  { code: "PL", name: "Poland", isSupportedInMangoPay: true },
  { code: "PT", name: "Portugal", isSupportedInMangoPay: true },
  { code: "PR", name: "Puerto Rico", isSupportedInMangoPay: true },
  { code: "QA", name: "Qatar", isSupportedInMangoPay: true },
  { code: "RE", name: "Reunion", isSupportedInMangoPay: true },
  { code: "RO", name: "Romania", isSupportedInMangoPay: true },
  { code: "RU", name: "Russian Federation", isSupportedInMangoPay: true },
  { code: "RW", name: "Rwanda", isSupportedInMangoPay: true },
  { code: "BL", name: "Saint Barthelemy", isSupportedInMangoPay: true },
  { code: "KN", name: "Saint Kitts and Nevis", isSupportedInMangoPay: true },
  { code: "LC", name: "Saint Lucia", isSupportedInMangoPay: true },
  { code: "MF", name: "Saint Martin (French part)", isSupportedInMangoPay: true },
  { code: "PM", name: "Saint Pierre and Miquelon", isSupportedInMangoPay: true },
  { code: "VC", name: "Saint Vincent and the Grenadines", isSupportedInMangoPay: true },
  { code: "WS", name: "Samoa", isSupportedInMangoPay: true },
  { code: "SM", name: "San Marino", isSupportedInMangoPay: true },
  { code: "ST", name: "Sao Tome and Principe", isSupportedInMangoPay: true },
  { code: "SA", name: "Saudi Arabia", isSupportedInMangoPay: true },
  { code: "SN", name: "Senegal", isSupportedInMangoPay: true },
  { code: "RS", name: "Serbia", isSupportedInMangoPay: true },
  { code: "SC", name: "Seychelles", isSupportedInMangoPay: true },
  { code: "SL", name: "Sierra Leone", isSupportedInMangoPay: true },
  { code: "SG", name: "Singapore", isSupportedInMangoPay: true },
  { code: "SX", name: "Saint Maarten", isSupportedInMangoPay: true },
  { code: "SK", name: "Slovakia", isSupportedInMangoPay: true },
  { code: "SI", name: "Slovenia", isSupportedInMangoPay: true },
  { code: "SB", name: "Solomon Islands", isSupportedInMangoPay: true },
  { code: "SO", name: "Somalia", isSupportedInMangoPay: true },
  { code: "ZA", name: "South Africa", isSupportedInMangoPay: true },
  { code: "GS", name: "South Georgia and the South Sandwich Islands", isSupportedInMangoPay: true },
  { code: "SS", name: "South Sudan", isSupportedInMangoPay: true },
  { code: "ES", name: "Spain", isSupportedInMangoPay: true },
  { code: "LK", name: "Sri Lanka", isSupportedInMangoPay: true },
  { code: "PS", name: "State of Palestine", isSupportedInMangoPay: true },
  { code: "SD", name: "Sudan", isSupportedInMangoPay: true },
  { code: "SR", name: "Suriname", isSupportedInMangoPay: true },
  { code: "SJ", name: "Svalbard and Jan Mayen", isSupportedInMangoPay: true },
  { code: "SZ", name: "Swaziland", isSupportedInMangoPay: true },
  { code: "SE", name: "Sweden", isSupportedInMangoPay: true },
  { code: "CH", name: "Switzerland", isSupportedInMangoPay: true },
  { code: "SY", name: "Syrian Arab Republic", isSupportedInMangoPay: true },
  { code: "TW", name: "Taiwan", isSupportedInMangoPay: true },
  { code: "TJ", name: "Tajikistan", isSupportedInMangoPay: true },
  { code: "TZ", name: "Tanzania United Republic Of", isSupportedInMangoPay: true },
  { code: "TH", name: "Thailand", isSupportedInMangoPay: true },
  { code: "TL", name: "Timor Leste", isSupportedInMangoPay: true },
  { code: "TG", name: "Togo", isSupportedInMangoPay: true },
  { code: "TK", name: "Tokelau", isSupportedInMangoPay: true },
  { code: "TO", name: "Tonga", isSupportedInMangoPay: true },
  { code: "TT", name: "Trinidad and Tobago", isSupportedInMangoPay: true },
  { code: "TN", name: "Tunisia", isSupportedInMangoPay: true },
  { code: "TR", name: "Turkey", isSupportedInMangoPay: true },
  { code: "TM", name: "Turkmenistan", isSupportedInMangoPay: true },
  { code: "TC", name: "Turks and Caicos Islands", isSupportedInMangoPay: true },
  { code: "TV", name: "Tuvalu", isSupportedInMangoPay: true },
  { code: "UG", name: "Uganda", isSupportedInMangoPay: true },
  { code: "UA", name: "Ukraine", isSupportedInMangoPay: true },
  { code: "AE", name: "United Arab Emirates", isSupportedInMangoPay: true },
  { code: "UM", name: "United States Minor Outlying Islands", isSupportedInMangoPay: true },
  { code: "UY", name: "Uruguay", isSupportedInMangoPay: true },
  { code: "VI", name: "Us Virgin Islands", isSupportedInMangoPay: true },
  { code: "UZ", name: "Uzbekistan", isSupportedInMangoPay: true },
  { code: "VU", name: "Vanuatu", isSupportedInMangoPay: true },
  { code: "VE", name: "Venezuela", isSupportedInMangoPay: true },
  { code: "VN", name: "Vietnam", isSupportedInMangoPay: true },
  { code: "WF", name: "Wallis and Futuna", isSupportedInMangoPay: true },
  { code: "EH", name: "Western Sahara", isSupportedInMangoPay: true },
  { code: "YE", name: "Yemen", isSupportedInMangoPay: true },
  { code: "ZM", name: "Zambia", isSupportedInMangoPay: true },
  { code: "ZW", name: "Zimbabwe", isSupportedInMangoPay: true }
];

export const regions = {
  US: [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "DC", name: "District of Columbia" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" }
  ],
  CA: [
    { code: "AB", name: "Alberta" },
    { code: "BC", name: "British Columbia" },
    { code: "MB", name: "Manitoba" },
    { code: "NL", name: "Newfoundland and Labrador" },
    { code: "NB", name: "New Brunswick" },
    { code: "NT", name: "Northwest Territories" },
    { code: "NS", name: "Nova Scotia" },
    { code: "NU", name: "Nunavut" },
    { code: "ON", name: "Ontario" },
    { code: "PE", name: "Prince Edward Island" },
    { code: "QC", name: "Quebec" },
    { code: "SK", name: "Saskatchewan" },
    { code: "YT", name: "Yukon" }
  ]
}

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const EVENTS_PAGE_STATUS = process.env.REACT_APP_EVENTS_PAGE_STATUS;
