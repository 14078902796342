import axios from "../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "./constants";

const events = {
  async getEvents(data) {
    try {
      const result = await axios.get(`${API_URL}/events?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default events;
