import React from "react";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Typography } from "antd";

import ResaleEventItem from "../Listing/ResaleEventItem";

import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import { getEventsRequest } from "../../redux/events/eventsActions";

import PartnersList from "../shared/PartnersList";
import SellBooking from "../shared/SellBooking";

import { partners } from "../../static/partners";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

export const ResaleEvents = ({
  getStaticPage,
  getEvents,
  pageData,
  events,
}) => {
  useServerSafeEffect(() => {
    getStaticPage();
    getEvents();
  }, []);

  return (
    <div className="main-content resale-deals">
      <Row>
        <Col span={24}>
          <Header/>
          <Row justify="space-around" align="middle" className="listing-list resale-event-list">
            <Col xs={{ span: 24 }} md={{ span: 20 }}>
              <Row>
                <Col xs={{ span: 24 }} className="listing-list__list-container">
                  <Row>
                    <Col span={24} className="listing-list__items">
                      <Row>
                        <Col xs={{ span: 24 }} xl={{ span: 18 }} className="flex-column">
                          <Typography.Title level={1} style={{ "marginBottom": "40px", "fontSize": "36px" }} className="listing-list__title">
                          Check Out our Resale Events Deals
                          </Typography.Title>
                        </Col>
                      </Row>
                      {
                        events.map(event => {
                          return (
                            <div
                              key={`event-${event.id}`}>
                              <ResaleEventItem event={event} />
                            </div>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <Typography.Title
                      className="resale-deals__section-title"
                      level={1}
                    >
                      {pageData.partnersTitle}
                    </Typography.Title>
                    <p>{pageData.partnersSubtitle}</p>
                    <PartnersList partners={partners}/>
                  </Col>
                </Row>
              </section>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <SellBooking content={pageData.sellRoomsContent} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer/>
    </div>
  )
};

ResaleEvents.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  pageData: PropTypes.any.isRequired,
  getStaticPage: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired
};

export default connect(
  state => ({
    isProcessing: state.listing.processing,
    pageData: state.staticPage.page,
    events: state.events.list
  }),
  {
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "resale-deals" }),
    getEvents: getEventsRequest,
  }
)(ResaleEvents);
