import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Table, Select, Input } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import { itemRender } from "../../../helpers/pagination";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";
import {
  deleteEventRequest,
  getEventsListRequest
} from "../../../redux/admin/events/eventsActions";

const EventsList = ({
  events,
  pagination,
  isProcessing,
  error,
  requestSuccess,
  deleteEvent,
  getEvents,
  showModal,
  hideModal
}) => {
  const [paginationObj, setPaginationObj] = useState({
    categoryId: null,
    pageSize: 10,
    sort: "-createdAt"
  });

  useEffect(() => {
    getEvents(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderImage = imageUrl => <div className="article-list__image"><img src={imageUrl} alt="cover-image" /></div>;

  const renderEditButton = record => (
    <Link to={`/admin/events/${record.key}`} >
      <img
        src="/images/edit.svg"
        alt="edit"
        className="feedback-list__delete"
      />
    </Link>
  );

  const renderDeleteButton = record => (
    <img
      src="/images/close.svg"
      alt="remove"
      className="feedback-list__delete"
      onClick={() => showModal({
        type: "confirm",
        title: "Are You sure you would like to delete event?",
        params: {
          isProcessing,
          error,
          confirmAction: () => deleteEvent({ id: record.key })
        }
      })}
    />
  );

  return (
    <Row className="feedback-list events-list">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row justify="space-between" align="top">
              <Col span={12} className="feedback-list__filters">
                <Select
                  className="feedback-list__filter events-list__filter-size"
                  defaultValue={paginationObj.pageSize}
                  onChange={value => {
                    setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                  }}
                >
                  <Select.Option value={10}>Show 10</Select.Option>
                  <Select.Option value={20}>Show 20</Select.Option>
                  <Select.Option value={30}>Show 30</Select.Option>
                </Select>
              </Col>
              <Col span={12} className="events-list__search-wrapper">
                <Input.Search
                  onChange={e => delayedSearch(e.target.value)}
                  onSearch={handleSearch}
                  placeholder="Search"
                  className="feedback-list__search"
                />
                <Link
                  to="/admin/events/new"
                  className="ant-btn button button--primary events-list__new"
                >
                  New Event
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="events-list__items"
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={events.map(event => {
                return {
                  key: event.id,
                  imageUrl: event.imageUrl,
                  name: event.name,
                  createdAt: moment(event.createdAt).format("DD MMM YYYY, hh:mmA")
                }
              })}
              columns={[
                {
                  title: "Image",
                  dataIndex: "imageUrl",
                  key: "imageUrl",
                  render: renderImage,
                  width: 100
                },
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  className: "events-list__items-name"
                },
                {
                  title: "Created",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                  sortDirections: ["descend", "ascend"],
                  width: 200
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderEditButton
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderDeleteButton
                }
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

EventsList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  getEvents: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.events.processing,
    error: state.admin.events.error,
    events: state.admin.events.data,
    pagination: {
      current: state.admin.events.curPage,
      total: state.admin.events.total,
      pageSize: state.admin.events.perPage
    },
    requestSuccess: state.admin.events.requestSuccess
  }),
  {
    getEvents: getEventsListRequest,
    deleteEvent: deleteEventRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(EventsList);
