import { all, put, call, takeLatest } from "redux-saga/effects";

import events from "../../../api/admin/events";
import * as types from "./eventsActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *getEvent ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(events.getEvent, data);
    yield put({ type: types.ADMIN_GET_EVENT_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_EVENT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *createEvent ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(events.createEvent, data);
    yield put({ type: types.ADMIN_CREATE_EVENT_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Event created successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_EVENT_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *updateEvent ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(events.updateEvent, data);
    yield put({ type: types.ADMIN_UPDATE_EVENT_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Event saved successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_EVENT_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *getEventsList ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(events.getEventsList, data);
    yield put({ type: types.ADMIN_GET_EVENTS_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_EVENTS_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *deleteEvent ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(events.deleteEvent, data);
    yield put({ type: types.ADMIN_DELETE_EVENT_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_EVENTS_LIST_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_EVENT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_EVENTS_LIST_REQUEST, getEventsList),
    yield takeLatest(types.ADMIN_GET_EVENT_REQUEST, getEvent),
    yield takeLatest(types.ADMIN_CREATE_EVENT_REQUEST, createEvent),
    yield takeLatest(types.ADMIN_UPDATE_EVENT_REQUEST, updateEvent),
    yield takeLatest(types.ADMIN_DELETE_EVENT_REQUEST, deleteEvent)
  ])
}
