import React from "react";
import { Route, Switch } from "react-router-dom";

import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";

import AdminRoutes from "./AdminRoutes";
import AuthRoutes from "./AuthRoutes";
import SellerRoutes from "./SellerRoutes";
import PublicRoutes from "./PublicRoutes";
import ListingRoutes from "./ListingRoutes";
import ProfileRoutes from "./ProfileRoutes";

import Seo from "../shared/Seo";
import Home from "../Home/Home";
import ResaleDeals from "../ResaleDeals/ResaleDeals";
import ResaleEvents from "../ResaleEvents/ResaleEvents";
import AccountVerify from "../SignUp/AccountVerify";
import GoogleSignIn from "../SignIn/GoogleSignIn";
import { EVENTS_PAGE_STATUS } from "../../api/constants";

function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/auth/verify/:token" component={AccountVerify} />
      <Route exact path="/auth/google-sign-in" component={GoogleSignIn} />
      <PublicRoute path="/auth" component={AuthRoutes} />
      <PrivateRoute path="/profile" component={ProfileRoutes} />
      <PrivateRoute path="/seller" component={SellerRoutes} />
      <PrivateRoute path="/admin" component={AdminRoutes} />
      <Route path="/listings" component={ListingRoutes} />
      <Route
        path="/resale-deals/:slug"
        render={(props) => (
          <Seo
            title="Resale Deals"
            description="Looking for a hotel deal? At PlansChange, we've got you covered all year round. Browse our hotel deals to find the right stay for you. Check in to great deals!"
          >
            <ResaleDeals {...props} />
          </Seo>
        )}
      />
      <Route
        path="/resale-deals"
        render={(props) => (
          <Seo
            title="Resale Deals"
            description="Looking for a hotel deal? At PlansChange, we've got you covered all year round. Browse our hotel deals to find the right stay for you. Check in to great deals!"
          >
            <ResaleDeals {...props} />
          </Seo>
        )}
      />
      { EVENTS_PAGE_STATUS === "enabled" && (
        <Route
          path="/resale-events"
          render={(props) => (
            <Seo
              title="Resale Events"
              description="Looking for a hotel deal? At PlansChange, we've got you covered all year round. Browse our hotel deals to find the right stay for you. Check in to great deals!"
            >
              <ResaleEvents {...props} />
            </Seo>
          )}
        />
      )}
      <Route
        path="/"
        exact
        render={(props) => (
          <Seo
            title="Book a Room With Free Cancellation & Flexibility"
            description="Say hello to your new booking experience. We've listened to you to offer hotel bookings with complete flexibiity. Checkin to something different! Plans change, we get it."
          >
            <Home {...props} />
          </Seo>
        )}
      />
      <Route path="/" component={PublicRoutes} />
    </Switch>
  );
}

export default AppRoutes;
